// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-find-js": () => import("./../../../src/pages/find.js" /* webpackChunkName: "component---src-pages-find-js" */),
  "component---src-pages-gathering-js": () => import("./../../../src/pages/gathering.js" /* webpackChunkName: "component---src-pages-gathering-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-holding-js": () => import("./../../../src/pages/holding.js" /* webpackChunkName: "component---src-pages-holding-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-message-js": () => import("./../../../src/pages/message.js" /* webpackChunkName: "component---src-pages-message-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-whoarewe-js": () => import("./../../../src/pages/whoarewe.js" /* webpackChunkName: "component---src-pages-whoarewe-js" */),
  "component---src-pages-you-js": () => import("./../../../src/pages/you.js" /* webpackChunkName: "component---src-pages-you-js" */)
}

